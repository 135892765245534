@import "~@/styles/variables/variables.scss";























































































































































































































































.login-container {
	position: relative;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background: url('~@/assets/imgs/bg/bg-login.png') no-repeat;
	background-color: #4b8af3;
	background-size: cover;
	background-position: center;
	.box-form {
		position: absolute;
		width: 440px;
		top: 50%;
		right: 14%;
		transform: translateY(-50%);
		background: rgba(0, 17, 102, 0.29);
		border-radius: 20px;

		.logo {
			height: 64px;
			display: block;
			margin: 60px auto 0;
		}

		.logo-szrq {
			height: 180px;
			display: block;
			margin: 30px auto 0;
		}

		.title {
			margin-top: 18px;
			font-size: 24px;
			font-weight: 600;
			color: #fff;
			text-align: center;
		}

		.login-form {
			padding: 60px 40px 70px;
			&.pdt {
				padding-top: 20px;
			}
			::v-deep {
				.el-form-item {
					margin-bottom: 30px;
					border: none;
					border-radius: 8px;
					&:focus-within {
						border-color: #4b8af3;
					}
					&__error {
						color: $base-color-error;
						margin-top: 4px;
					}
					&.is-error {
						margin-bottom: 30px;
					}

					input:-webkit-autofill,
					input:-webkit-autofill:hover,
					input:-webkit-autofill:focus,
					input:-webkit-autofill:active {
						-webkit-transition-delay: 99999s;
						-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
					}
					.el-input__inner {
						padding: 0 10px;
						height: 44px;
						border: none;
						color: #666;
						line-height: 24px;

						&::placeholder {
							color: #d3dae6;
						}
					}

					.icon {
						font-size: 18px;
						line-height: 36px;
					}
					.iconfont {
						line-height: 44px;
						font-size: 28px;
						margin-right: 8px;
					}
				}

				.el-input-group__prepend {
					background: #fff;
					border: none;
					padding: 0 2px 0 20px;
					min-width: 40px;
					.icon-prepend {
						width: 18px;
						height: 18px;
						position: relative;
						top: 2px;
						&.code {
							margin-top: 13px;
						}
					}
				}

				.el-input.code-input {
					display: flex;
					.el-input-group__append {
						padding: 0;
						flex: 0 0 100px;
						width: 100px;
						border: none;
						overflow: hidden;
						box-sizing: border-box;
						background-color: rgba(215, 230, 250, 1);
						font-size: 0 !important;
					}
					.code-img {
						margin-top: 0;
						display: inline-block;
						width: 100%;
						height: 44px;
						object-fit: contain !important;
						overflow: hidden;
					}
				}
			}
			.el-button.login-btn {
				margin-top: 10px;
				width: 100%;
				height: 44px;
				background: #5975ff;
				border-radius: 8px;
				border: none;
				font-size: 16px;
				color: #fff;
			}
		}
	}
	.copyright {
		position: absolute;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		color: #fff;
	}
	@media screen and (max-width: 800px) {
		.box-form {
			width: 90%;
			left: 50%;
			right: none;
			transform: translate(-50%, -50%);
		}
	}
}
